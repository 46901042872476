<template>
  <footer class="app-footers">
    <!-- 高德地图 -->
    <div class="map">
      <div id="amap">
        <div id="container"></div>
      </div>
    </div>
    <!-- 其它 -->
    <div class="extra">
      <div class="container">
        <div class="about">
          <!-- 关于我们 -->
          <div class="left">
            <div class="about-us">关于我们</div>
            <ul class="contact-address">
              <li>地址：{{ Gs.address1 }}</li>
              <li class="address">{{ Gs.address2 }}</li>
              <li>邮箱：{{ Gs.email }}</li>
              <li>电话：{{ Gs.tel1 }}{{ Gs.tel2 }}</li>
            </ul>
          </div>
          <!-- 联系我们 -->
          <div class="right">
            <div class="contact-us">联系我们</div>
            <div class="qrcode">
              <div class="leftcode">
                <img :src="Gs.gzh" alt="" />
                <div>公众号</div>
              </div>
              <div class="rightcode">
                <img :src="Gs.weibo" alt="" />
                <div>微博</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 版权信息 -->
        <div class="copyright">
          <div class="copyright-top">
            <a href=""> Copyright©www.gsjyvip.com</a>
          </div>
          <p>
            <img class="record" src="@/assets/images/index/位图.png" alt="" />
            <a href="https://beian.miit.gov.cn/#/Integrated/index"
              >京ICP备19030070号-1</a
            >
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// 引入高德地图
import AMapLoader from "@amap/amap-jsapi-loader";
import { getGsForm } from "@/api/http";
export default {
  data() {
    return {
      map: null,
      Gs: "",
      flag: true,
    };
  },
  mounted() {
    // 初始化页面后直接初始化地图
    this.getGs();
    this.initMap();
  },
  watch: {
    "$store.state.actived": function () {
      //console.log(this.$store.state.actived)
      if (this.$store.state.actived == "down") {
        document.querySelector(".map").style.marginLeft = "0";
      } else {
        document.querySelector(".map").style.marginLeft = "104px";
      }
    },
  },
  created() {},
  methods: {
    getGs() {
      getGsForm().then((data) => {
        //console.log(data.data);
        this.Gs = data.data;
      });
    },
    initMap() {
      AMapLoader.load({
        key: "383dcb6ce4f12e17006449a5b006245c",
        version: "2.0",
        plugins: [
          "AMap.ControlBar",
          "AMap.ToolBar", //工具条，控制地图的缩放、平移等
          "AMap.Scale", //比例尺，显示当前地图中心的比例尺
          "AMap.HawkEye", //鹰眼，显示缩略图
          "AMap.MapType", //图层切换，用于几个常用图层切换显示
        ],
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            resizeEnable: true, //是否监控地图容器尺寸变化
            viewMode: "2D", //是否为3D地图模式
            expandZoomRange: true,
            zoom: 16, //初始化地图级别
            zooms: [3, 18],
            center: [116.3141117, 39.877796], //初始化地图中心点位置
            // center: [116.431716, 39.988315], //初始化地图中心点位置
            cursor: "hand",
          });
          var marker = new AMap.Marker({
            position: new AMap.LngLat(116.3141117, 39.877796), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            content: `
          <div class="my_markers" >
            <div class="titleBoxs">
              <img src="https://www.gsjyvip.com/public/address.jpg" alt="" />
            </div>
            <div class="content-imgs"></div>
          </div>
              `,
            offset: new AMap.Pixel(5, 14),
          });
          this.map.add(marker);
          this.map.addControl(
            new AMap.ToolBar({
              position: "LT",
            })
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  destroyed() {},
};
</script>
<style>
.my_markers {
  height: 83px;
  position: relative;
}
.my_markers img {
  width: 300px;
  position: absolute;
  top: -170px;
  left: -178px;
}
/* .my_markers .titleBoxs {
  position: absolute;
  left: 0px;
  top: -15px;
}

.my_markers .titleBoxs .titleBgcs {
  width: 300px;
  height: 67px;
  position: absolute;
  top: -160px;
  left: -160px;
  background: url("../assets/images/index/高德标点背景216x56.png") no-repeat;
  background-size: 100%, 100%;
}

.my_markers .titleBoxs .titles {
  white-space: nowrap;
  position: absolute;
  top: -150px;
  left: -150px;
  overflow: hidden;
  background: url("../assets/images/index/高德标点216x68.png") no-repeat;
  background-size: 100%, 100%;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 1.2px;
  padding: 15px 23px 29px 23px;
} */

.my_markers .content-imgs {
  width: 30px;
  height: 46px;
  background: url("../assets/images/index/marker.png") no-repeat;
  background-size: 100%, 100%;
  position: absolute;
  bottom: 120px;
  left: -40px;
}
</style>
<style scoped lang="less">
@import url("../assets/styles/appFooter.less");
</style>
